:root {
  --color-paragraph-gray: #636363;
  --color-text-light: #ffff;
  --color-text-light-gray: #718096;
  --color-subtitle-text-dark: #3d3d3d;
  --color-secondary: #ffff;
  --color-title-light: #FFFFFF;
  --color-paragraph-light: #FFFFFF;
  --color-button-orange: #F39200;
}
