$color-paragraph-dark: #706F6F;
$color-texts: #231f1f;
$color-secondary: #ffff;
$color-subtitle-text: #ffffff;
$color-button-primary-orange: #f18a00;
$color-text-orange-light: #f59e24;
$color-background-selected-item: #f5ba43;
$color-button-alegro: #EB72EB;
$color-button-blue: #0b799d;
$color-dodger-blue: #1E90FF;
$color-button-primary-yellow: #fdbf00;
$color-button-green: #8fd100;
$color-background-blue-light: #008b96;
$color-primary: #3d3d3d;
$color-background-section-light: rgba(107, 70, 193, 0.15);
$color-background-section: #f7f8fb;
$color-button-primary-gray: #63656a;
$color-light: #718096;
$color-background-section-gray-light: #F0F0F0;
$color-text-gray: #636363;
$color-button-shadow: rgba(0, 0, 0, 0.15);
$color-input-shadow: rgba(229, 103, 23, 0.075);
$color-gray-border: #C4C4C4;
$color-gray-border-2: #878787;
$color-red-border: #f70303;
$color-orange-two: #f39200;
$color-gray: #939393;
$color-gray-text: #808080;
$color-background-section-gray: #ABABAB;
$color-primary-dark: #63656A;
$color-text-black: #575756;
$color-text-white: #FFFFFF;
$background-gray: #EFEFEF;
$border-gray: #D9D9D9;
$color-secondary-dark: #98989A;
$color-white: #FFFFFF;
$text-red: #f70303;