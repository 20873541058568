@use 'index' as s;
@use 'primeng/resources/themes/lara-light-blue/theme.css';
@use 'primeng/resources/primeng.min.css';

html,
body {
  height: 100%;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
body {
  margin: 0;
  font-family: s.$primary-family;
}
