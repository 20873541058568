@use 'colors' as c;
@use 'displayed' as d;
@use 'typography' as t;

.main{
  @include d.flex-column;
  width: 100%;
  padding-top: 56px;
  margin: 0 auto;
  align-items: center;
}


.container {
  width: 82%;
  max-width: 1250px;
  display: flex;
}

.inputSearch{
width: 300px;
}

input,
select {
  width: 100%;
  height: 2.2rem;
  background-color: c.$color-secondary;
  border: 1px solid c.$color-gray-border;
  border-radius: 12px;
  color: c.$color-text-gray;
  padding-left: 10px;
  font-family: t.$secondary-family;
  font-style: t.$font-style-normal;
  font-weight: t.$font-weight-normal;
  font-size: 0.8rem;
}
/*input {
  @include d.flex-column;
  align-content: flex-end;
  position: relative;
}*/

select {
  @include d.flex-row;
  justify-content: center;
  position: relative;
}

input:focus, select:focus, input[type]:focus {
  border-color: 1px solid c.$color-gray-border-2;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075)inset, 0 0 3px c.$color-gray-border;
  outline: 0 none;
}

.border-ok {
  border: 1px solid c.$color-gray-border;
}

.border-error {
  border: 1px solid c.$color-red-border;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075)inset, 0 0 3px c.$color-subtitle-text;
  outline: 0 none;
}

textarea {
  @include d.flex-column;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
  background-color: c.$color-secondary;
  border: 1px solid c.$color-gray-border;
  border-radius: 12px;
  color: c.$color-text-gray;
  padding-left: 10px;
  font-family: t.$secondary-family;
  font-style: t.$font-style-normal;
  font-weight: t.$font-weight-normal;
  font-size: 0.8rem;
  align-content: flex-end;
  position: relative;
}

textarea:focus {
  border-color: 1px solid c.$color-gray-border-2;
  box-shadow: 0 1px 1px c.$color-input-shadow inset, 0 0 3px c.$color-gray-border;
  outline: 0 none;
}

