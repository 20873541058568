@use "sass:map";

/// Breakpoints names
$base: 'base';
// $xs: 'xs'
$sm: 'sm';
// $md: 'md'
$lg: 'lg';
$xl: 'xl';
// $xxl: 'xxl'

/// Breakpoints
$breakpoints: ($base: 0px, $sm: 800px, $lg: 1400px, $xl: 1920px);

@mixin absoluteCenter {
  justify-content: center;
  align-items: center;
  align-content: center;
}

@mixin _flex {
  display: flex;
}

@mixin _flex-row {
  @include _flex;
  flex-direction: row;
}

@mixin flex-row-wrap {
  @include _flex-row;
  flex-wrap: wrap;
}

@mixin flex-row {
  @include _flex-row;
}

@mixin flex-column {
  @include _flex;
  flex-direction: column;
}

@mixin flex-center-items {
  align-items: center;
  justify-content: center;
}

@mixin media-query($breakpoint-name, $min-width: 0){
  @if map-has-key($breakpoints, $breakpoint-name){
    @media (min-width: map.get($breakpoints, $breakpoint-name)) {
      @content
    }
  }
  @else {
    @media (min-width: $min-width) {
      @content
    }
  }
}
