@use "typography" as *;
@use "colors" as *;
@use "displayed" as *;

.primaryButtonMd {
  background-color: $color-button-primary-orange;
  color: $color-subtitle-text;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
  cursor: pointer;
}

.disablePrimaryButtonMd {
  background-color: $color-background-selected-item;
  color: $color-subtitle-text;
  width: 160px;
  height: 40px;
  border-radius: 12px;
  font-family: $secondary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
}

.primaryPinkButtonMd {
  background-color: $color-button-alegro;
  color: $color-subtitle-text;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
}
.primaryBlueButtonMd {
  background-color: $color-button-blue;
  color: $color-subtitle-text;
  width: 130px;
  height: 35px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  transition: all 0.4s ease-in-out;
  font-weight: $font-weight-bold;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}

.primaryOrangeButtonMd {
  background-color: $color-button-primary-orange;
  color: $color-subtitle-text;
  width: 136px;
  height: 35px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  transition: all 0.4s ease-in-out;
  font-weight: $font-weight-bold;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}

.primaryYellowButtonMd {
  background-color: $color-button-primary-yellow;
  color: $color-subtitle-text;
  width: 130px;
  height: 35px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  transition: all 0.4s ease-in-out;
  font-weight: $font-weight-bold;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}

.button-lg {
  width: 170px;
  height: 35px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  transition: all 0.4s ease-in-out;
  font-weight: $font-weight-bold;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
}

.button-xl {
  width: 220px;
}

.primary-blue-button {
  background-color: $color-button-blue;
  color: $color-subtitle-text;
}

.primary-green-button {
  background-color: $color-button-green;
  color: $color-subtitle-text;
}

.primary-yellow-button {
  background-color: $color-button-primary-yellow;
  color: $color-subtitle-text;
}

.primary-orange-button {
  background-color: $color-button-primary-orange;
  color: $color-subtitle-text;
}

@media screen and (min-width: 900px) {
  .button-lg {
    width: 180px;
    height: 40px;
    font-size: 15px;
    line-height: 20px;
  }

  .button-xl {
    width: 240px;
  }

  .primaryBlueButtonMd,
  .primaryOrangeButtonMd,
  .primaryYellowButtonMd {
    width: 160px;
    height: 40px;
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1500px) {
  .button-lg {
    width: 240px;
    height: 50px;
    font-size: 20px;
    line-height: 20px;
  }

  .button-xl {
    width: 300px;
  }

  .primaryBlueButtonMd,
  .primaryOrangeButtonMd {
    width: 220px;
    height: 50px;
    font-size: 20px;
    line-height: 28px;
  }
}

.primaryCyanButtonMd {
  background-color: $color-background-blue-light;
  color: $color-subtitle-text;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
}
.primaryButtonGrayMd {
  background-color: $color-primary;
  color: $color-subtitle-text;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 26px;
  align-items: center;
}

.primaryButtonLightMd {
  background: $color-background-section-light;
  color: $color-primary;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
}

.secondaryButtonMd {
  background-color: $color-background-section;
  color: $color-button-primary-gray;
  width: 160px;
  height: 40px;
  border: 1px solid $color-button-primary-gray;
  border-radius: 8px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
}

/* --  SMALL BUTTONS -- */

.primaryButtonSm {
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: 12px;
  margin: 0px 7px auto 7px;
  width: 100px;
  height: 28px;
  background-color: $color-button-primary-gray;
  color: $color-subtitle-text;
  padding: 3px;
  border-radius: 8px;
}

.primaryButtonLightSm {
  background: $color-background-section-light;
  border-radius: 5px;
  color: $color-primary;
  width: 130px;
  height: 30px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
}

.primaryButtonLightTextSm {
  background: $color-light;
  border-radius: 5px;
  color: $color-secondary;
  width: 130px;
  height: 30px;
  margin-top: 30px;
  margin-left: 5px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
}

.secondaryButtonSm {
  background-color: $color-secondary;
  border-radius: 5px;
  color: $color-secondary;
  width: 200px;
  height: 35px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  padding: 5px;
}

.secondaryButtonLightSm {
  background-color: $color-button-primary-orange;
  border-radius: 5px;
  color: $color-subtitle-text;
  width: 200px;
  height: 35px;
  font-family: $primary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  padding: 5px;
}

/* NEW LIBRARY */

/* Para fondos Orange  */

.getPlanButton {
  font-family: $secondary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 4px $color-button-shadow;
  border-radius: 12px;
  background-color: $color-background-section-gray-light;
  color: $color-text-gray;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 2.8rem;
}

.basicButton {
  @include flex-row;
  font-family: $secondary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-bold;
  font-size: 0.9rem;
  line-height: 1.5rem;
  text-align: center;
  box-shadow: 0 4px 4px $color-button-shadow;
  border-radius: 12px;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 35px;
}

.buttonSmall {
  font-family: $secondary-family;
  font-style: $font-style-normal;
  font-weight: $font-weight-normal;
  font-size: 13px;
  width: 100px;
  height: 28px;
  background-color: $color-button-primary-gray;
  color: $color-subtitle-text;
  padding: 3px;
  border-radius: 8px;
}
